import React from 'react';

const CareerPlan = (props) => {
    return (
        <div className="p-3 h-full">
            <div className="shadow-2 p-3 h-full flex flex-column surface-card" style={{ borderRadius: '6px' }}>
                <div className="text-900 font-medium text-xl mb-2">{props.title}</div>
                <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                        <span>Detail: </span>
                    </li>
                    <ul>
                        <li>Required amount in your total volume : <b>{parseFloat(props.required_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</b></li>
                        <li>Maximum earning per day in binary : <b>No limit</b></li>
                    </ul>
                    <li className="mt-5 flex align-items-center mb-3">
                        <span>Gift: <b>{parseFloat(props.gift).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</b></span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default CareerPlan;