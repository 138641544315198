import React from 'react';

const Contact = () => {
    return (
        <div className="grid">
            <div className="col">
                <div className="card">
                    <div className="text-700 text-center">
                        <div className="text-blue-600 font-bold mb-3">POWERED BY Smart training institue</div>
                        <div className="text-900 font-bold text-5xl mb-3">Feel free to contact us</div>
                        <div className="text-700 text-2xl mb-5">Our support is available by email to member@smarttraininginstitute.com</div>                  
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;