import React from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export const NotFound = (props) => {
    const navigate = useNavigate();

    const goDashboard = () => {
        navigate('/');
    };

    return (
        <div className="exception-body notfound">
            <div className="exception-wrapper">
                <div className="exception-content">
                    <img src="assets/layout/images/pages/asset-404.svg" alt="freya-layout" />
                    <span>404</span>
                    <span className="exception-subtitle">Looks like you are lost.</span>
                    <Button type="button" label="Go back to home" onClick={goDashboard}></Button>
                </div>
                <div className="exception-footer p-mt-4">
                    <h4>Smart Training Institute</h4>
                    <h6>Copyright Ⓒ 2023</h6>
                </div>
            </div>
        </div>
    );
};
