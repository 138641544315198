import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppRightPanel from './AppRightPanel';

import Dashboard from './components/Dashboard';
import Training from './components/Training';
import Learning from './components/Learning';
import Network from './components/Network';
import Wallet from './components/Wallet';
import Deposit from './components/Deposit';
import Career from './components/Career';
import Transaction from './components/Transaction';
import Transfer from './components/Transfer';
import Dincomes from './components/Dincomes';
import Tincomes from './components/Tincomes';
import Cincomes from './components/Cincomes';
import Tpackage from './components/Tpackage';
import Direct from './components/Direct';
import News from './components/News';
import Marketing from './components/Marketing';
import Faq from './components/Faq';
import Profil from './components/Profil';
import Setting from './components/Setting';
import Contact from './components/Contact';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import { NotFound } from './pages/NotFound';

const App = (props) => {
    const [resetActiveIndex, setResetActiveIndex] = useState(null);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState('sidebar');
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('filled');
    const [ripple, setRipple] = useState(false);
    const [rightPanelActive, setRightPanelActive] = useState(false);
    const [colorScheme, setColorScheme] = useState('light');
    const [topbarScheme, setTopbarScheme] = useState('light');
    const [menuScheme, setMenuScheme] = useState('light');
    const [themeScheme, setThemeScheme] = useState('light');
    const [theme, setTheme] = useState('blue');
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    const menu = [
        {
            label: 'Dashboard',
            icon: 'pi pi-home',
            to: '/'
        },
        {
            label: 'Package',
            icon: 'pi pi-bolt',
            to: '/Training'
        },
        {
            label: 'Training',
            icon: 'pi pi-desktop',
            to: '/Learning'
        },
        {
            label: 'Network',
            icon: 'pi pi-sitemap',
            to: '/Network'
        },
        {
            label: 'Wallet',
            icon: 'pi pi-wallet',
            to: '/Wallet'
        },
        {
            label: 'Deposit',
            icon: 'pi pi-plus',
            to: '/Deposit'
        },
        {
            label: 'Career',
            icon: 'pi pi-th-large',
            to: '/Career'
        },
        {
            label: 'Summary',
            icon: 'pi pi-list',
            items: [
                { label: 'Transaction', icon: 'pi pi-list', to: 'Summary/Transaction'},
                { label: 'Direct incomes', icon: 'pi pi-list', to: 'Summary/Dincomes'},
                { label: 'Training incomes', icon: 'pi pi-list', to: 'Summary/Tincomes'},
                { label: 'Career incomes', icon: 'pi pi-list', to: 'Summary/Cincomes'},
                { label: 'Transfer', icon: 'pi pi-list', to: 'Summary/Transfer'},
                { label: 'Direct', icon: 'pi pi-list', to: 'Summary/Direct'},
                { label: 'Package bought', icon: 'pi pi-list', to: 'Summary/Tpackage'}
            ]
        },
        {
            label: 'News',
            icon: 'pi pi-info-circle',
            to: '/News'
        },
        {
            label: 'Marketing',
            icon: 'pi pi-share-alt',
            to: '/Marketing'
        },
        {
            label: 'FAQ',
            icon: 'pi pi-search',
            to: '/Faq'
        },
        {
            label: 'Profil',
            icon: 'pi pi-user',
            to: '/Profil'
        },
        {
            label: 'Settings',
            icon: 'pi pi-cog',
            to: '/Setting'
        },
        {
            label: 'Contact',
            icon: 'pi pi-comments',
            to: '/Contact'
        },
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            to: '/Logout'
        }
    ];

    let menuClick;
    let rightPanelClick;
    let configClick;
    let searchClick;
    let topbarUserMenuClick;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        } else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setResetActiveIndex(true);
            hideOverlayMenu();
        }
        if (!event.item.items && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }
    };

    const onMenuClick = (event) => {
        if (menuActive && event.target.className === 'layout-menu-container') {
            setResetActiveIndex(true);
            setMenuActive(false);
        }
        menuClick = true;
    };

    const onMenuModeChange = (menuMode) => {
        setMenuMode(menuMode);
        if (menuMode === 'sidebar') {
            if (sidebarStatic) {
                setSidebarActive(true);
            }
        } else {
            setSidebarActive(false);
            if (topbarScheme !== menuScheme) {
                setMenuScheme(topbarScheme);
            }
        }
        if (topbarScheme === 'dark') {
            setThemeScheme('dark');
        }
    };

    const onColorSchemeChange = (scheme) => {
        setColorScheme(scheme);
        props.setColorScheme(scheme);
    };

    const onThemeSchemeChange = (scheme) => {
        setThemeScheme(scheme);
        setMenuScheme(scheme);
        setTopbarScheme(scheme);
    };

    const onTopbarSchemeChange = (scheme) => {
        setTopbarScheme(scheme);
    };

    const onMenuSchemeChange = (scheme) => {
        setMenuScheme(scheme);
    };

    const onThemeChange = (themeColor) => {
        setTheme(themeColor);
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setRightPanelActive(false);

        if (isMobile()) {
            setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
            if (staticMenuMobileActive) {
                blockBodyScroll();
            } else {
                unblockBodyScroll();
            }
        }
        event.preventDefault();
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const hideOverlayMenu = () => {
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const onRightPanelClick = () => {
        rightPanelClick = true;
    };

    const onRightPanelButtonClick = () => {
        setRightPanelActive((prevState) => !prevState);
        rightPanelClick = true;
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive((prevConfigActive) => !prevConfigActive);
        configClick = true;
    };

    const onTopbarSearchToggle = () => {
        setSearchActive((prevState) => !prevState);
        searchClick = true;
    };

    const onTopbarSearchClick = () => {
        searchClick = true;
    };

    const onTopbarUserMenuClick = () => {
        setTopbarUserMenuActive((prevState) => !prevState);
        topbarUserMenuClick = true;
    };

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            setSearchActive(false);
            searchClick = false;
        }

        if (!topbarUserMenuClick && topbarUserMenuActive) {
            setTopbarUserMenuActive(false);
            topbarUserMenuClick = false;
        }

        if (!rightPanelClick && rightPanelActive) {
            setRightPanelActive(false);
        }

        if (!configClick && configActive) {
            setConfigActive(false);
        }

        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setResetActiveIndex(true);
                setMenuActive(false);
            }

            if (staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        searchClick = false;
        topbarUserMenuClick = false;
        rightPanelClick = false;
        configClick = false;
        menuClick = false;
    };

    const onSidebarMouseOver = () => {
        setSidebarActive(!isMobile());
    };

    const onSidebarMouseLeave = () => {
        setSidebarActive(false);
    };

    const onToggleMenu = (event) => {
        menuClick = true;
        setSidebarStatic((prevState) => !prevState);

        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const layoutClassName = classNames(
        'layout-wrapper',
        {
            'layout-sidebar': menuMode === 'sidebar',
            'layout-static': menuMode === 'sidebar' && sidebarStatic,
            'layout-horizontal': menuMode === 'horizontal',
            'layout-rightpanel-active': rightPanelActive,
            'layout-slim': menuMode === 'slim',
            'layout-mobile-active': staticMenuMobileActive,
            'p-input-filled': inputStyle === 'filled',
            'p-ripple-disabled': !ripple
        },
        'layout-menu-' + menuScheme + ' layout-topbar-' + topbarScheme
    );

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar
                topbarScheme={topbarScheme}
                onRightPanelButtonClick={onRightPanelButtonClick}
                searchActive={searchActive}
                onTopbarSearchToggle={onTopbarSearchToggle}
                onTopbarSearchClick={onTopbarSearchClick}
                topbarUserMenuActive={topbarUserMenuActive}
                onTopbarUserMenuClick={onTopbarUserMenuClick}
                menu={menu}
                menuActive={menuActive}
                onRootMenuItemClick={onRootMenuItemClick}
                mobileMenuActive={staticMenuMobileActive}
                onMenuItemClick={onMenuItemClick}
                menuMode={menuMode}
                sidebarStatic={sidebarStatic}
                sidebarActive={sidebarActive}
                onSidebarMouseOver={onSidebarMouseOver}
                onSidebarMouseLeave={onSidebarMouseLeave}
                onToggleMenu={onToggleMenu}
                onMenuButtonClick={onMenuButtonClick}
                resetActiveIndex={resetActiveIndex}
                onMenuClick={onMenuClick}
            />

            <AppRightPanel onRightPanelClick={onRightPanelClick} />

            <AppConfig
                configActive={configActive}
                onConfigButtonClick={onConfigButtonClick}
                onConfigClick={onConfigClick}
                menuMode={menuMode}
                onMenuModeChange={onMenuModeChange}
                ripple={ripple}
                onRippleChange={onRippleChange}
                inputStyle={inputStyle}
                onInputStyleChange={onInputStyleChange}
                colorScheme={colorScheme}
                onColorSchemeChange={onColorSchemeChange}
                topbarScheme={topbarScheme}
                onTopbarSchemeChange={onTopbarSchemeChange}
                menuScheme={menuScheme}
                onMenuSchemeChange={onMenuSchemeChange}
                themeScheme={themeScheme}
                onThemeSchemeChange={onThemeSchemeChange}
                theme={theme}
                onThemeChange={onThemeChange}
            />

            <div className="layout-main">
                <div className="layout-content">
                    <Routes>
                        <Route path="/" exact="true" element={<Dashboard />} />
                        <Route path="Dashboard" exact="true" element={<Dashboard />} />
                        <Route path="Training" exact="true" element={<Training />} />
                        <Route path="Learning" exact="true" element={<Learning />} />
                        <Route path="Network" exact="true" element={<Network />} />
                        <Route path="Career" exact="true" element={<Career />} />
                        <Route path="Wallet" exact="true" element={<Wallet />} />
                        <Route path="Deposit" exact="true" element={<Deposit />} />
                        <Route path="Summary/Transaction" exact="true" element={<Transaction />} />
                        <Route path="Summary/Dincomes" exact="true" element={<Dincomes />} />
                        <Route path="Summary/Tincomes" exact="true" element={<Tincomes />} />
                        <Route path="Summary/Cincomes" exact="true" element={<Cincomes />} />
                        <Route path="Summary/Transfer" exact="true" element={<Transfer />} />
                        <Route path="Summary/Direct" exact="true" element={<Direct />} />
                        <Route path="Summary/Tpackage" exact="true" element={<Tpackage />} />
                        <Route path="News" exact="true" element={<News />} />
                        <Route path="Marketing" exact="true" element={<Marketing />} />
                        <Route path="Faq" exact="true" element={<Faq />} />
                        <Route path="Profil" exact="true" element={<Profil />} />
                        <Route path="Setting" exact="true" element={<Setting />} />
                        <Route path="Contact" exact="true" element={<Contact />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>

                <AppFooter />
            </div>

            <div className="layout-mask modal-in"></div>
        </div>
    );
};

export default App;
