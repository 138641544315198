import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import WalletService from '../service/WalletService';
import { useNavigate } from 'react-router-dom';

const Wallet = () => {
    const navigate = useNavigate();
    const toast = useRef();
    const [displayConfirmationWithdrawal, setDisplayConfirmationWithdrawal] = useState(false);
    const [displayConfirmationTransfer, setDisplayConfirmationTransfer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const confirmationDialogFooterWithdrawal = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmationWithdrawal(false)} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => sendWithdrawal(inputWithdrawal)} className="p-button-text" autoFocus />
        </>
    );
    const confirmationDialogFooterTransfer = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmationTransfer(false)} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => sendTransfer(inputTransfer)} className="p-button-text" autoFocus />
        </>
    );
    const [external_wallet, setExternal_wallet] = useState(0.00);
    const [internal_wallet, setInternal_wallet] = useState(0.00);
    const [inputWithdrawal, setInputWithdrawal] = useState({
      amount_withdrawal: '',
      ga_withdrawal: '',
      pin_withdrawal: ''
    });
    const [inputTransfer, setInputTransfer] = useState({
      amount_transfer: '',
      login_transfer: '',
      ga_transfer: '',
      pin_transfer: ''
    });
    const [dataTransfer, setDataTransfer] = useState({
        amount: '',
        name: '',
        firstname: '',
      });
    const walletService = new WalletService();
    useEffect(() => {
        walletService.getDepositPending().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
        });
        walletService.getLiveWalletData().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setExternal_wallet(data.external_live_amount);
            setInternal_wallet(data.internal_live_amount);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeWithdrawal = (e) => {
        setInputWithdrawal({ ...inputWithdrawal, [e.target.id]: e.target.value });
    };

    const handleChangeTransfer = (e) => {
        setInputTransfer({ ...inputTransfer, [e.target.id]: e.target.value });

    };
    const sendPin = () =>{
        setIsLoading(true);
        walletService.sendPin().then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            else
            {
                toast.current.show({ severity: res.status.type, summary: res.status.type, detail: res.status.message, life: 3000 });
            }
        }).catch(() => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: "An error has occured", life: 3000 });
        }).finally(() => {
            setIsLoading(false);
        });
    }
    const sendWithdrawal = (param) =>{
        setDisplayConfirmationWithdrawal(false)
        walletService.sendWithdrawal(param).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === "error")
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.status.message, life: 3000 });
            }
        });
    }
    const sendTransfer = (param) =>{
        setDisplayConfirmationTransfer(false)
        walletService.sendTransfer(param).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === "error")
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.status.message, life: 3000 });
                walletService.getLiveWalletData().then((data) => {
                    if(data === 401)
                    {
                        navigate('/expiration');
                    }
                    setExternal_wallet(data.external_live_amount);
                    setInternal_wallet(data.internal_live_amount);
                });
            }
        });
    }

    const getDataTransfer = () =>{
        walletService.dataTransfer(inputTransfer).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === "error")
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
            }
            else
            {
                setDataTransfer(res.data);
                setDisplayConfirmationTransfer(true)
            }
        });
    }

    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12 pb-0">
                <Toast ref={toast} />
                    <div className="grid">
                        <div className="col">
                            <div className="card overview-box darkgray">
                                <div className="overview-info">
                                    <h6>External wallet</h6>
                                    <h1>{external_wallet} $</h1>
                                </div>
                                <i className="pi pi-wallet"></i>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card overview-box darkgray">
                                <div className="overview-info">
                                    <h6>Internal wallet</h6>
                                    <h1>{internal_wallet} $</h1>
                                </div>
                                <i className="pi pi-arrow-right-arrow-left"></i>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 xs:col-12 md:col-12 lg:col-6">
                            <div className="card p-fluid">
                                <h5>Withdrawal from external wallet</h5>
                                <div className="field">
                                    <label htmlFor="amount_withdrawal">Amount ($):</label>
                                    <InputText id="amount_withdrawal" type="number" min="10" step="1" value={inputWithdrawal.amount_withdrawal} onChange={handleChangeWithdrawal}/>
                                    <small>Minimum : 10 usd - fees : 3%</small>
                                </div>
                                <div className="field">
                                    <label htmlFor="ga_withdrawal">Google authenticator code:</label>
                                    <InputText id="ga_withdrawal" type="text" keyfilter="int" value={inputWithdrawal.ga_withdrawal} onChange={handleChangeWithdrawal}/>
                                    <small>Please fill the google auth code if it is enabled in your settings</small>
                                </div>
                                <div className="field">
                                    <label htmlFor="pin_withdrawal">Pin from email:</label>
                                    <div className="p-inputgroup mr-2 mb-2">
                                        <InputText id="pin_withdrawal" type="text" keyfilter="int" value={inputWithdrawal.pin_withdrawal} onChange={handleChangeWithdrawal}/>
                                        <Button label="Ask a pin" icon="pi pi-send" disabled={isLoading} onClick={() => sendPin()}>
                                            {isLoading && <i className="pi pi-spin pi-spinner" />}
                                        </Button>
                                    </div>
                                </div>
                                <Button label="Submit" icon="pi pi-check" className="p-button-success" onClick={() => setDisplayConfirmationWithdrawal(true)} />
                                <Dialog header="Confirmation" visible={displayConfirmationWithdrawal} onHide={() => setDisplayConfirmationWithdrawal(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooterWithdrawal}>
                                    <div className="flex align-items-center justify-content-center">
                                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                        <span>Are you sure you want to proceed to the withdrawal?</span>
                                    </div>
                                </Dialog>
                            </div>
                        </div>
                        <div className="col-12 xs:col-12 md:col-12 lg:col-6">
                            <div className="card p-fluid">
                                <h5>Transfer</h5>
                                <div className="field">
                                    <label htmlFor="amount_transfer">Amount ($):</label>
                                    <InputText id="amount_transfer" type="number" min="10" step="1" keyfilter="int" value={inputTransfer.amount_transfer} onChange={handleChangeTransfer}/>
                                    <small>Minimum : 10 usd - fees 1%</small>
                                </div>
                                <div className="field">
                                    <label htmlFor="login_transfer">Receiver login:</label>
                                    <InputText id="login_transfer" type="text" keyfilter="alphanum" value={inputTransfer.login_transfer} onChange={handleChangeTransfer}/>
                                </div>
                                <div className="grid formgrid">
                                    <div className="col-12 mb-2 lg:col-6 lg:mb-0">
                                        <div className="field">
                                            <label htmlFor="ga_transfer">Google authenticator code:</label>
                                            <InputText id="ga_transfer" type="text"  keyfilter="int" value={inputTransfer.ga_transfer} onChange={handleChangeTransfer}/>
                                            <small>Please fill the google auth code if it is enabled in your settings</small>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-2 lg:col-6 lg:mb-0">
                                        <div className="field">
                                            <label htmlFor="pin_transfer">Pin from email:</label>
                                            <div className="p-inputgroup mr-2 mb-2">
                                                <InputText id="pin_transfer" type="text" keyfilter="int" value={inputTransfer.pin_transfer} onChange={handleChangeTransfer}/>
                                                <Button label="Ask a pin" icon="pi pi-send" disabled={isLoading} onClick={() => sendPin()}>
                                                    {isLoading && <i className="pi pi-spin pi-spinner" />}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Button label="Submit" icon="pi pi-check" className="p-button-success" onClick={() => getDataTransfer()} />
                                <Dialog header="Confirmation" visible={displayConfirmationTransfer} onHide={() => setDisplayConfirmationTransfer(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooterTransfer}>
                                    <div className="flex align-items-center justify-content-center">
                                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                        <span>Sending <b>{dataTransfer.amount && dataTransfer.amount} USD</b> to <b>{dataTransfer.name && dataTransfer.name} {dataTransfer.firstname && dataTransfer.firstname}</b>.<br/>
                                        Are you sure you want to proceed to the transfer ?</span>
                                    </div>
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Wallet;