import React, { useRef,useState, useEffect } from 'react';
import ProfilService from '../service/ProfilService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';

const Setting = () => {
    
    const navigate = useNavigate();
    const [profil, setProfil] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const profilService = new ProfilService();

    useEffect(() => {
        profilService.getProfil().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setProfil(data);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const handleChange = (e) => {
        setProfil({ ...profil, [e.target.id]: e.target.value });
    };

    const handleSubmit = () => {
      setIsLoading(true);
      profilService.update(profil).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            toast.current.show({ severity: res.status.type, summary: res.status.type, detail: res.status.message, life: 3000 });
        });
      setIsLoading(false);
    };
    
    return (
        <div className="grid p-fluid">
            <div className="col-12 md:col-6">
            <Toast ref={toast} />
                <div className="card">
                    <h5>Personnal information</h5>
                    <div className="grid formgrid">
                        <div className="col-12 mb-2 lg:col-6 lg:mb-0">
                            <div className="field">
                                <label htmlFor="inputtext">Login</label>
                                <InputText type="text" id="inputtext" value={profil?.login || ''} placeholder="Login" disabled/>
                            </div>
                            <div className="field">
                                <label htmlFor="inputtext">Email</label>
                                <InputText type="text" id="inputtext" value={profil?.email || ''} placeholder="Email" disabled/>
                            </div>
                            <div className="field">
                                <label htmlFor="inputtext">Name</label>
                                <InputText type="text" id="name" value={profil?.name || ''} onChange={handleChange} placeholder="Name"/>
                            </div>
                            <div className="field">
                                <label htmlFor="inputtext">Firstname</label>
                                <InputText type="text" id="firstname" value={profil?.firstname || ''} onChange={handleChange} placeholder="Firstname"/>
                            </div>
                            <div className="field">
                                <label htmlFor="birthdate">Birthdate</label>
                                <InputText type="date" id="birthdate" value={profil?.birthdate || ''}  onChange={handleChange} placeholder="Birthdate"/>
                            </div>
                        </div>
                        <div className="col-12 mb-2 lg:col-6 lg:mb-0">
                            <div className="field">
                                <label htmlFor="inputtext">Country</label>
                                <InputText type="text" id="inputtext" value={profil?.country || ''}  placeholder="Country" disabled/>
                            </div>
                            <div className="field">
                                <label htmlFor="city">City</label>
                                <InputText type="text" id="city" value={profil?.city || ''}  onChange={handleChange} placeholder="City"/>
                            </div>
                            <div className="field">
                                <label htmlFor="zipcode">Zip code</label>
                                <InputText type="text" id="zipcode" value={profil?.zipcode || ''}  onChange={handleChange} placeholder="Zip code"/>
                            </div>
                            <div className="field">
                                <label htmlFor="street">Street</label>
                                <InputText type="text" id="street" value={profil?.street || ''}  onChange={handleChange} placeholder="Street"/>
                            </div>
                            <div className="field">
                                <label htmlFor="number">Number</label>
                                <InputText type="text" id="number" value={profil?.number || ''}  onChange={handleChange} placeholder="Number"/>
                            </div>

                        </div>
                        <Button label="submit" disabled={isLoading} onClick={handleSubmit}>
                        {isLoading && <i className="pi pi-spin pi-spinner" />}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Setting;