import React, { useRef,useState, useEffect } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import DepositService from '../service/DepositService';
import { useNavigate } from 'react-router-dom';
import {QRCodeSVG} from 'qrcode.react';
import { useInterval } from 'primereact/hooks';

const Deposit = () => {
    const navigate = useNavigate();
    const toast = useRef();
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const confirmationDialogFooter = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => confirmCancel()} className="p-button-text" autoFocus />
        </>
    );
    const [internal_wallet, setInternal_wallet] = useState(0.00);
    const [is_pending, setis_pending] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [amount_to_pay, setAmount_to_pay] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [seconde, setSeconds] = useState(30);
    const [depositData, setDepositData] = useState({
        "payment_id": null,
        "member_input_amount": 0.00,
        "pay_amount": 0.00,
        "pay_address": null,
        "pay_currency" : null,
        "payment_status": null});
    const depositService = new DepositService();
    useEffect(() => {
        depositService.getLiveWalletData().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setInternal_wallet(data.internal_live_amount);
        });
        depositService.getDepositPending().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data)
            {
                setDepositData(data);
                setis_pending(true);
                if(data.payment_status === "confirming" || data.payment_status === "confirmed" || data.payment_status === "finished" || data.payment_status === "partially_paid" || data.payment_status === "sending")
                {
                    setConfirming(true);
                }
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useInterval(
        () => {
            setSeconds((prevSecond) => (prevSecond === 1 ? 29 : prevSecond - 1)); //fn
            if(seconde === 1)
            {
                getPendingDeposit();
            }
        },
        1000,   //delay (ms)
        is_pending  //condition (when)
    );

    const confirmCancel = () => {
        setIsLoading(true);
        setDisplayConfirmation(false)
        depositService.cancelDeposit().then((response) => {
            if(response === 401)
            {
                navigate('/expiration');
            }
            if(response)
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.status.message, life: 3000 });
                setis_pending(false);
            }
        });
        setIsLoading(false);
    };

    const addDeposit = (amount_to_pay) => {
        setIsLoading(true);
        depositService.addDeposit(amount_to_pay).then((response) => {
            if(response === 401)
            {
                navigate('/expiration');
            }
            if(response)
            {
                toast.current.show({ severity: response.status.type, summary: response.status.type, detail: response.status.message, life: 3000 });
                getPendingDeposit();
            }
            setIsLoading(false);
        });
    };

    const getPendingDeposit = () => {
        depositService.getDepositPending().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data)
            {
                setDepositData(data);
                setis_pending(true);
                if(data.payment_status === "confirming" || data.payment_status === "confirmed" || data.payment_status === "partially_paid")
                {
                    setConfirming(true);
                    getWallet();
                }
                if(data.payment_status === "expired")
                {
                    toast.current.show({ severity: "error", summary: "Error", detail: "You deposit has been expired, please cancel it and create a new one.", life: 3000 });
                }
            }
        });
    };

    const getWallet = () => {
        depositService.getLiveWalletData().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setInternal_wallet(data.internal_live_amount);
        });
    };

    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-6 pb-0">
                    <div className="grid">
                        <div className="col-12">
                            <div className="card overview-box darkgray">
                                <div className="overview-info">
                                    <h6>Internal Wallet</h6>
                                    <h1>{internal_wallet} $</h1>
                                </div>
                                <i className="pi pi-wallet"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 pb-0">
                    <div className="grid">
                        <Toast ref={toast} />
                        <h3>Deposits have been temporarily disabled.</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Deposit;