import AxiosService from './AxiosService';

export default class WalletService {
    async getAmount() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/network/amount');
            return res.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async getLoadDirect(param) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.post('/dashboard/network/loaddirect',param);
            return res.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async expandNode(param) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.post('/dashboard/network/expanddirect',{'key':param});
            return res.data;
        } catch (error) {
            return error.response.status;
        }
    }
}
