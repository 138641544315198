import React, { useRef, useState,useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import SettingService from '../service/SettingService';
import { useNavigate } from 'react-router-dom';
import {QRCodeSVG} from 'qrcode.react';

const Setting = () => {
    
    const navigate = useNavigate();
    const [sponsor, setSponsor] = useState(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayBasic2, setDisplayBasic2] = useState(false);
    const [isGAActive, setIsGAActive] = useState(false);
    const [gacode, setGacode] = useState('');
    const [url, setUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const basicDialogFooter = <Button type="button" label="Dismiss" onClick={() => setDisplayBasic(false)} icon="pi pi-check" className="p-button-secondary" />;
    const basicDialogFooter2 = <Button type="button" label="Dismiss" onClick={() => setDisplayBasic2(false)} icon="pi pi-check" className="p-button-secondary" />;
    const [inputAddress, setInputAddress] = useState({
        "address": '',
        "gaAddress": '',
        "pinAddress": ''});
    const toast = useRef();
    const handleInputChange = (event) => {
      setGacode(event.target.value);
    };
    const settingService = new SettingService();
    useEffect(() => {
        settingService.gaIsActive().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data === 1)
            {
                setIsGAActive(true);
            }
            else
            {
                setIsGAActive(false);
            }
        });
        settingService.getAddress().then((data) => {
            setInputAddress({ ...inputAddress, "address": data });
        });
        settingService.getSponsor().then((data) => {
            setSponsor(data.data);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRemoveGA = () => {
        settingService.gaRemove(gacode).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === 'success')
            {
                setDisplayBasic2(false);
                setIsGAActive(false);
            }
            toast.current.show({ severity: res.status.type, summary: res.status.type, detail: res.status.message, life: 3000 });
        });
    };
    const handleCreateGA = () => {
        settingService.gaCreate().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data.status.type === 'error')
            {
                toast.current.show({ severity: data.status.type, summary: data.status.type, detail: data.status.message, life: 3000 });
                
            }
            else
            {
                setUrl(data.data);
                setDisplayBasic(true);
            }
        });
    };
    const handleConfirmCodeGA = () => {
        settingService.gaCheckCodeCreation(gacode).then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data === true)
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Google auth activated', life: 3000 });
                setDisplayBasic(false);
                setIsGAActive(true);
            }
            else
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Wrong code', life: 3000 });
            }
        });
    };
    const handleChangeAddress = (e) => {
        setInputAddress({ ...inputAddress, [e.target.id]: e.target.value });
    };

    const handleSubmit = () => {
        settingService.updateAddress(inputAddress).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === "error")
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.status.message, life: 3000 });
                // setInputAddress TODO clean the pin and ga
            }
        });
    };

    const sendPin = () =>{
        setIsLoading(true);
        settingService.sendPin().then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            toast.current.show({ severity: res.status.type, summary: res.status.type, detail: res.status.message, life: 3000 });
        }).catch(() => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: "An error has occured", life: 3000 });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <div className="grid">
            <div className="col-12 md:col-6">
                <div className="card">
                    <Toast ref={toast} />
                    <Dialog header="Google authenticator set up" visible={displayBasic} style={{ width: '30vw' }} modal footer={basicDialogFooter} onHide={() => setDisplayBasic(false)}>
                        <p>Step 3: Scan te follow QRCODE with your google authenticator application</p>
                        <div className='flex justify-content-center'><QRCodeSVG value={url} /></div>
                        <p>Step 4: Enter the STI 6 digit code from your google authenticator and click on send</p>
                        <div className="mr-2 mb-2">
                            <InputText value={gacode} onChange={handleInputChange}/>
                            <Button label="Send" icon="pi pi-send"  onClick={handleConfirmCodeGA}/>
                        </div>
                    </Dialog>
                    <Dialog header="Remove google authenticator" visible={displayBasic2} style={{ width: '30vw' }} modal footer={basicDialogFooter2} onHide={() => setDisplayBasic2(false)}>
                        <p>Enter your 6 digit code and click on submit to remove your google authenticator</p>
                        <div className="mr-2 mb-2">
                            <InputText value={gacode} onChange={handleInputChange}/>
                            <Button label="Submit" icon="pi pi-send" onClick={handleRemoveGA}/>
                        </div>
                    </Dialog>
                    <h5>Google auth</h5>
                    {!isGAActive ? (
                        <>
                            <p>Step 1: Download and install Google Authenticator from one of these stores:</p>
                            <a target="blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=fr&gl=US"><Button label="Play store" icon="pi pi-google" className="mr-2 mb-2"/></a>
                            <a target="blank" href="https://apps.apple.com/fr/app/google-authenticator/id388497605"><Button label="Apple store" icon="pi pi-apple" className="mr-2 mb-2"/></a>
                            <p>Step 2: Click the follow button to add smart training institute (STI) to your google authenticator :</p>
                            <Button label="Create a google auth" icon="pi pi-google" className="mr-2 mb-2"  onClick={handleCreateGA} />
                        </>   
                    ) : (
                        <>
                            <Button label="Remove my Google auth" icon="pi pi-times" className="mr-2 mb-2 p-button-danger"  onClick={() => setDisplayBasic2(true)} />
                        </> 
                    )}
                    
                </div>
                <div className="card">
                    <h5>Sponsor information</h5>
                    <p>Your sponsor : {sponsor && sponsor.login} ({sponsor && sponsor.email})</p>
                </div>
                <div className="card p-fluid">
                    <h5>Withdrawal information</h5>
                    <div className="grid formgrid">
                        <div className="col-12 mb-2 lg:col-12 lg:mb-0">
                            <div className="field">
                                <label htmlFor="address">Withdrawal address</label>
                                <InputText type="text" id="address" value={inputAddress.address} onChange={handleChangeAddress} placeholder="Please enter your usdt trc20 address"/>
                            </div>
                            <div className="field">
                                <label htmlFor="pinAddress">Pin</label>
                                <div className="p-inputgroup mr-2 mb-2">
                                    <InputText type="text" id="pinAddress" value={inputAddress.pin} onChange={handleChangeAddress}/>
                                    <Button label="Ask a pin" icon="pi pi-send" disabled={isLoading} onClick={() => sendPin()}>
                                        {isLoading && <i className="pi pi-spin pi-spinner" />}
                                    </Button>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="gaAddress">Google auth</label>
                                <InputText type="text" id="gaAddress" value={inputAddress.ga} onChange={handleChangeAddress}/>
                                <small>Please fill the google auth code if it is enabled in your settings</small>
                            </div>
                        </div>
                        <Button label="Submit" className="mr-2 mb-2" onClick={handleSubmit}></Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Setting;