import React, { useState, useEffect,useRef } from 'react';
import TrainingPlan from './layout/TrainingPlan';
import TrainingService from '../service/TrainingService';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';

const Training = () => {
    
    const navigate = useNavigate();
    const [trainings, setTrainings] = useState([]);
    const [currentPackage, setCurrentPackage] = useState(0);
    const trainingService = new TrainingService();
    const toast = useRef();
    useEffect(() => {
        trainingService.getPackage().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setTrainings(data.training);
            if(data.current)
            {
                setCurrentPackage(data.current)
            }
        }).catch((error) =>{
            navigate('/expiration');
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const buyPackage = (amount) => {
        trainingService.buyPackage(amount).then((res) => {
            if(res.status.type === 'error')
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.status.message, life: 3000 });
                renderPackage();
            }
        });
    };

    const renderPackage = () => {
        trainingService.getPackage().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setTrainings(data.training);
            if(data.current)
            {
                setCurrentPackage(data.current)
            }
        }).catch((error) =>{
            navigate('/expiration');
        });
    }

    return (
        <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
            <div className="text-900 font-bold text-6xl mb-4 text-center">Training package</div>
            <div className="text-700 text-xl mb-6 text-center line-height-3">Maximize your potential earnings and minimize risk with our training plan, designed to give you the knowledge and skills you need to succeed in the competitive world of trading. Each training pack give you access to training videos and lots of features to earn more on Smart Training Institute.</div>
            <Toast ref={toast} />
            <h3>Your total package : {currentPackage.toFixed(2)} $</h3>
            <div className="grid">
                {trainings && trainings.map((training, index) => (
                    <div key={index} className="col-12 lg:col-4">
                    <TrainingPlan description={training.description} title={training.name} price={training.amount} percent={training.expiration_cap} buyPackage={buyPackage} expInfo={training.exp_info} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Training;