import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export const Expiration = () => {
const navigate = useNavigate();
localStorage.removeItem('token');
console.clear();
  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <img src={`${process.env.PUBLIC_URL}/assets/layout/images/logook.svg`} alt="freya-layout" />
            <h3>Your token has been expired please sign in again</h3>
            <Button label="Go to login" onClick={() => navigate('/login')}></Button>
        </div>
        <div className="login-footer">
          <h4>Smart training institute</h4>
          <h6>Copyright Ⓒ 2023</h6>
        </div>
      </div>
    </div>
  );
};
