import React, { useRef,useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import NetworkService from '../service/NetworkService';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Network = () => {

    const navigate = useNavigate();
    const toast = useRef();
    const [selection, setSelection] = useState([]);
    const [tree, setTree] = useState([]);
    const [isBackAvailable, setIsBackAvailable] = useState(false);
    const [amount, setAmount] = useState({
    "login": '',
    "volume": 0.00,
    "member": 0,
    "percent": 0.00});

    const [loading, setLoading] = useState(true);
    const [currentLogin, setCurrentLogin] = useState('');
    const [previousMember, setPreviousMember] = useState('');
    const [firstMember, setFirstMember] = useState('');
    const treeService = new NetworkService();
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        treeService.getLoadDirect().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setTree(data.data.tree);
            setPreviousMember(data.data.current);
            setFirstMember(data.data.current);
        });
        treeService.getAmount().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setAmount(data.data);
            setCurrentLogin(data.data.login);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const expand_node = (key) => {
        if(key === "0")
        {
            toast.current.show({ severity: 'error', summary: 'warning', detail: "This position if free", life: 5000 });
        }
        else
        {
            setLoading(true);
            treeService.expandNode(key).then((res) => {
                if(res === 401)
                {
                    navigate('/expiration');
                }
                if(res.status.type === 'error')
                {
                    toast.current.show({ severity: 'error', summary: 'error', detail: res.status.message, life: 3000 });
                }
                else
                {
                    setTree(res.data.tree);
                    if(res.data.current.login === amount.login)
                    {
                        setIsBackAvailable(false);
                    }
                    else
                    {
                        setIsBackAvailable(true);
                    }
                    setPreviousMember(res.data.current.sponsorKey);
                    setCurrentLogin(res.data.current.login);
                }
                setLoading(false);
            });
        }
    };

    const onRowSelect = (event) => {
        if(event.data.member === 0)
        {
            toast.current.show({ severity: 'info', summary: 'No referral found for this member', detail: '', life: 3000 });
        }
        else
        {
            expand_node(event.data.key);
        }
    };

    return (
        <>
        <Toast ref={toast} />
        <div className="card overflow-x-auto">
            <div className="grid">
                <div className="col-12 lg:col-4 xs:col-12">
                    <Button label="Back" disabled={!isBackAvailable} className="mr-2 mb-2" icon="pi pi-step-backward" onClick={() => expand_node(previousMember)}/>
                    <Button label="My tree" className="mr-2 mb-2" icon="pi pi-fast-backward" onClick={() => expand_node(firstMember)}/>
                    <h3>Current member : {currentLogin}</h3>
                </div>
                <div className="col-12 lg:col-4 xs:col-12">
                    <div className='text-center'>
                        <h5>{amount.login}</h5>
                        <p><b>Volume : {amount.volume} $</b></p>
                        <p><b>Member : {amount.member}</b></p>
                        <p><b>Percent : {amount.percent} %</b></p>
                    </div>
                </div>
            </div>
        </div>
        <div className="card">
            <DataTable 
                value={tree} 
                selectionMode="single" 
                paginator 
                rows={10} 
                selection={selectedProduct} 
                onSelectionChange={(e) => setSelectedProduct(e.value)} 
                dataKey="key"
                onRowSelect={onRowSelect} 
                emptyMessage="No referrals found" 
                metaKeySelection={false} 
                tableStyle={{ minWidth: '50rem' }}>
                <Column field="login" header="Login" sortable ></Column>
                <Column field="member" header="Member" sortable ></Column>
                <Column field="total_paid" header="Package ($)" sortable ></Column>
                <Column field="volume" header="Volume ($)" sortable ></Column>
                <Column field="total_volume" header="Total volume ($)" sortable></Column>
                <Column field="percent" header="Percent (%)" sortable></Column>
            </DataTable>
        </div>
        </>
    )
}

export default Network;
            