import React, { useRef,useState,useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import AxiosService from './../service/AxiosService';
import { Toast } from 'primereact/toast';

export const Recovery = () => {
    const toast = useRef();
    const url =  new AxiosService();
    const apiUrl = url.apiUrl;
    const navigate = useNavigate();
    const params = useParams();
    const { token } = params;
    
    const [inputData, setInputData] = useState({
        token:token,
        password: '',
        password2: '',
    });
    
    const [isLoading, setIsLoading] = useState(false);
    const [isUrlOk, setisUrlOk] = useState(false);

    useEffect(() => {
        if(token.length === 0)
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'This url is expired', life: 3000 });
        }
        else
        {
            axios.post(apiUrl + '/auth/recovery/check/url', {"token":token})
            .then((response) => {
            if (response.data.status.type === "success")
            {
                setisUrlOk(true);
            }
            else
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.status.message, life: 3000 });
            }
            })
            .catch(() => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error has occured, please contact our support', life: 3000 });
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    const handleChange = (e) => {
      setInputData({ ...inputData, [e.target.id]: e.target.value });
    };
  
    const handleSubmit = (e) => {
        setIsLoading(true);
        if(inputData.password.length === 0 || inputData.password2.length === 0)
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please fill the form', life: 3000 });
            setIsLoading(false);
        }
        else if(inputData.password !== inputData.password2)
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Password and password repeat not match', life: 3000 });
            setIsLoading(false);
        }
        else
        {
            axios.post(apiUrl + '/auth/recovery/check/password', inputData)
            .then((response) => {
            if (response.data.status.type === "success")
            {
                setisUrlOk(false);
                setIsLoading(false);
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.status.message, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.status.message, life: 3000 });
                setIsLoading(false);
            }
            })
            .catch(() => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error has occured, please contact our support', life: 3000 });
            });
        }
        
    };

    return (
        <div className="login-body">
            <div className="login-wrapper">
                <div className="login-panel">
                <Toast ref={toast} />
                <img src={`${process.env.PUBLIC_URL}/assets/layout/images/logook.svg`} alt="freya-layout" />
                {isUrlOk ? (
                    <>
                    <InputText id="password" type="password" value={inputData.password} onChange={handleChange} placeholder="Your new password"/>
                    <small>Followings characters not allowed : <b>&</b>, <b>'</b>, <b>"</b>, <b>{'<'}</b> , <b>{'>'}</b></small>
                    <InputText id="password2" type="password" value={inputData.password2} onChange={handleChange} placeholder="Repeat your password"/>
                    <Button label="Send" type="submit" disabled={isLoading} onClick={handleSubmit}>
                        {isLoading && <i className="pi pi-spin pi-spinner" />}
                    </Button>
                    </>
                ) : (
                    <Button label="Go to Login" onClick={() => navigate('/login')} />
                )}
                </div>
                <div className="login-footer">
                    <h4>Smart training institute</h4>
                    <h6>Copyright Ⓒ 2023</h6>
                </div>
            </div>
        </div>
    );
};
