import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { ProgressBar } from 'primereact/progressbar';
import DashboardService from '../service/DashboardService';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const Dashboard = () => {
    const navigate = useNavigate();
    const [amount, setAmount] = useState({});
    const [lastLogin, setlastLogin] = useState(null);
    const [lastTsNews, setlastTsNews] = useState(null);
    const [rate, setRate] = useState({});
    const [members, setMembers] = useState([]);
    const [news_list, setNews_list] = useState([]);
    const [career, setCareer] = useState([]);
    const [careerName, setCareerName] = useState('');
    const [careerGift, setCareerGift] = useState(0);
    const [visibleCareer, setVisibleCareer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [differenceInDays, setDifferenceInDays] = useState(null);
    const basicDialogFooter = <Button type="button" label="Dismiss" onClick={() => setVisibleCareer(false)} icon="pi pi-check" className="p-button-secondary" />;
    const basicDialogFooterNews = <Button type="button" label="Open the page" onClick={() => navigate('/news')} icon="pi pi-check" className="p-button-primary" />;
    const dashboardService = new DashboardService();
    

    useEffect(() => {
        dashboardService.getDepositPending().then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
        });
        dashboardService.getAmount().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setAmount(data);
        });
        dashboardService.getChart().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setRate(data);
        });
        dashboardService.getDirect().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setMembers(data);
        });
        dashboardService.getNews().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setNews_list(data);
            if(data.last_login.length > 1)
            {
                setlastLogin(data.last_login[1].ts);
                setlastTsNews(data.news[0].ts);
            }
        });
        dashboardService.getCareer().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setCareer(data);
        });
        dashboardService.checkCareerReach().then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === "success")
            {
                setCareerName(res.data.name);
                setCareerGift(res.data.gift);
                setVisibleCareer(true);
            }
        });
        // INCENTIVE
            // const today = new Date(); // Current date
            // const futureDate = new Date('2023-07-12'); // Future date
            // const differenceInTime = futureDate.getTime() - today.getTime();
            // setDifferenceInDays(Math.ceil(differenceInTime / (1000 * 3600 * 24)));
        // INCENTIVE
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastLogin && Date.parse(lastTsNews) > Date.parse(lastLogin)) {
            const checkNews = localStorage.getItem('checkNews');
            if(checkNews && checkNews !== lastTsNews)
            {
                setShowModal(true);
            }
            localStorage.setItem('checkNews', lastTsNews);
        }
      }, [lastLogin, lastTsNews]);

    const chartOptions2 = {
        plugins: {
            legend: {
                display: false
            }
        },
        maintainAspectRatio: false,
        hover: {
            mode: 'index'
        },
        scales: {
            x: {
                display: true,
                grid: {
                    color: 'transparent'
                },
                ticks: {
                    color: '#BFC2C6'
                }
            },
            y: {
                display: true,
                grid: {
                    color: 'rgba(191, 194, 198, .45)',
                    borderDash: [5, 10]
                },
                ticks: {
                    color: '#BFC2C6',
                    min: 0,
                    stepSize: 5
                }
            }
        }
    };
    
    const getChart = () => {
        const borderColor = getComputedStyle(document.body).getPropertyValue('--primary-light-color') || '#2c84d8';
        const backgroundColor = getComputedStyle(document.body).getPropertyValue('--primary-lighter-color') || '#2c84d8';
        return {
            labels: rate.ts,
            datasets: [
                {
                    label: 'Rate',
                    data: rate.rate,
                    borderColor: [borderColor],
                    borderWidth: 4,
                    fill: true,
                    backgroundColor: [backgroundColor],
                    tension: 0.4
                }
            ]
        };
    };
    
    const chart2 = getChart();
    return (
        <div className="layout-dashboard">
            <Dialog header="Congratulation" visible={visibleCareer} style={{ width: '50vw' }} modal footer={basicDialogFooter} onHide={() => setVisibleCareer(false)}>
                <h3 className="m-0">
                    You have reach a new career : {careerName}
                </h3>
                <h3 className="m-0">
                    You gift : {careerGift} $
                </h3>
            </Dialog>
            <Dialog header="Stay Up-to-Date!" visible={showModal} modal footer={basicDialogFooterNews} onHide={() => setShowModal(false)}>
                <h3 className="m-0">
                    New updates are available on the news page. Don't miss out!
                </h3>
            </Dialog>
            <div className="grid">
                {/* <div className="col-12 md:col-6 sm:col-12 xl:col-6">
                    <div className="notification">
                        <h6>
                            You have an incredible opportunity to join the incentive trip in Turkey, but time is running out with only <b>{differenceInDays}</b> days remaining. To secure your spot, all you need to do is exceed the target of bringing in a minimum of 25,000 USD in deposit to your weakest branch. Don't miss out on this chance to experience an amazing adventure and be recognized for your outstanding achievement!<button className="p-link" onClick={() => navigate('/news')}>More information...</button>
                        </h6>
                    </div>
                </div>
                <div className="col-12 md:col-6 sm:col-12 xl:col-6">
                    <div className="notification">
                        <h4>COUNT DOWN BEFORE THE END OF THE INCENTIVE FOR TURKEY: <b>{differenceInDays} DAYS</b></h4>
                        <h6><button className="p-link" onClick={() => navigate('/news')}>More information...</button></h6>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="notification">
                        <h6>
                        Hello {amount.login} ! <br/>Here is your referal link: my.smarttraininginstitute.com/register/{amount.login} <div  className='mt-3'><a className="p-link bg-primary text-white p-1" style={{ cursor: 'pointer'}}  target="blank" href={"https://my.smarttraininginstitute.com/register/"+amount.login+""}>Register page</a></div>
                        </h6>
                        {amount.email_is_confirmed === 0 ? (
                            <p className='text-lg' style={{color:'red'}}>Please confirm your email by clicking on the link received by email. If you didn't received your email, please contact the support.</p>
                        ) : null}
                        {amount.is_gain_blocked === 1 ? (
                            <p className='text-lg' style={{color:'red'}}>You need to have a valid training package to start earning.</p>
                        ) : null}
                    </div>
                </div>
                <div className="col-12 pb-0">
                    <div className="grid">
                        <div className="col">
                            <div className="card overview-box">
                                <div className="overview-info">
                                    <h6>Weekly rate</h6>
                                    <h1>{amount.rate} %</h1>
                                </div>
                                <i className="pi pi-chart-line"></i>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card overview-box darkgray">
                                <div className="overview-info">
                                    <h6>Wallet</h6>
                                    <h1>{amount.external_wallet_live_amount} $</h1>
                                </div>
                                <i className="pi pi-wallet"></i>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card overview-box darkgray">
                                <div className="overview-info">
                                    <h6>Wallet intern</h6>
                                    <h1>{amount.internal_wallet_live_amount} $</h1>
                                </div>
                                <i className="pi pi-arrow-right-arrow-left"></i>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card overview-box darkgray">
                                <div className="overview-info">
                                    <h6>Career</h6>
                                    <h1>{amount.name}</h1>
                                </div>
                                <i className="pi pi-star"></i>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card overview-box darkgray">
                                <div className="overview-info">
                                    <h6>Training package</h6>
                                    {amount && amount.is_gain_blocked === 0 ? (
                                        <h1>{amount.training_amount ? amount.training_amount : '0.00'} $</h1>
                                    ) : <h1>0.00</h1>}
                                </div>
                                <i className="pi pi-user"></i>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col">
                            <div className="card overview-box blue">
                                <div className="overview-info">
                                    <h6>Total incomes</h6>
                                    <h1>{amount.total_income} $</h1>
                                </div>
                                <i className="pi pi-dollar"></i>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card overview-box blue">
                                <div className="overview-info">
                                    <h6>Direct incomes</h6>
                                    <h1>{amount.direct_income} $</h1>
                                </div>
                                <i className="pi pi-sort-amount-down-alt"></i>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card overview-box blue">
                                <div className="overview-info">
                                    <h6>Training incomes</h6>
                                    <h1>{amount.training_income} $</h1>
                                </div>
                                <i className="pi pi-bolt"></i>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card overview-box blue">
                                <div className="overview-info">
                                    <h6>Career incomes</h6>
                                    <h1>{amount.career_income} $</h1>
                                </div>
                                <i className="pi pi-th-large"></i>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card overview-box darkgray">
                                <div className="overview-info">
                                    <h6>Tree volume</h6>
                                    <h1>{amount.tree_volume} $</h1>
                                </div>
                                <i className="pi pi-sitemap"></i>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Line chart begin */}
                <div className="col-12 md:col-12 xl:col-8">
                    <div className="grid">
                        <div className="col-12">
                            <div className="card chart">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>Rate chart</h6>
                                        <p className="subtitle">Last week</p>
                                    </div>
                                </div>
                                <Chart type="line" data={chart2} options={chartOptions2} style={{ maxHeight: '330px' }}></Chart>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Line chart end */}
                {/* Rate statistic begin */}
                <div className="col-12 xl:col-4">
                    <div className="grid">
                        <div className="col-12 md:col-6 xl:col-12">
                            <div className="card statistics">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>Incomes statistics</h6>
                                        <p className="subtitle">Last 7 days</p>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-12 md:col-6">
                                        <div className="statistic-item">
                                            <div className="item-title">
                                                <h5>{amount.total_7_days ? amount.total_7_days : ' 0.00'} $</h5>
                                            </div>
                                            <h6>Total incomes</h6>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="statistic-item">
                                            <div className="item-title">
                                            {/* <i className="pi pi-sort-amount-down-alt"></i> */}
                                                <h5>{amount.direct_7_days ? amount.direct_7_days : ' 0.00'} $</h5>
                                            </div>
                                            <h6>Direct incomes</h6>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="statistic-item">
                                            <div className="item-title">
                                                {/* <i className="pi pi-bolt"></i> */}
                                                <h5>{amount.roi_7_days ? amount.roi_7_days : ' 0.00'} $</h5>
                                            </div>
                                            <h6>Training incomes</h6>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="statistic-item">
                                            <div className="item-title">
                                            {/* <i className="pi pi-th-large"></i> */}
                                                <h5>{amount.career_7_days ? amount.career_7_days : ' 0.00'} $</h5>
                                            </div>
                                            <h6>Career incomes</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Rate statistic end */}
                {/* x5 limit */}
                <div className="col-12 md:col-12 xl:col-12">
                    <div className="grid">
                        <div className="col-12">
                            <div className="card device-status">
                                <div className="grid">
                                    <div className="col-12 xl:col-12">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <h6>x5 break limit</h6>
                                            </div>
                                        </div>
                                        <p className="content">Your maximum amount to earn with your current package.</p>
                                        <b className='content'>{amount.total_income ? amount.total_income : '0.00'} $ / {amount.x5_total_amount_paid ? (amount.x5_total_amount_paid * 5).toFixed(2) : '0.00'} $</b>
                                        <div className="progress">
                                            <ProgressBar className="progressBar" value={Math.round((amount.total_income/(amount.x5_total_amount_paid * 5))*100)} showValue={true}></ProgressBar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* x5 limit */}
                {/* Career summary begin */}
                <div className="col-12 md:col-6 xl:col-9">
                    <div className="grid">
                        <div className="col-12">
                            <div className="card device-status">
                                <div className="grid">
                                    <div className="col-12 xl:col-12">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <h6>Career goal</h6>
                                            </div>
                                        </div>
                                        <p className="content">Your target to move to the next career: <b>{career.name && career.name}</b></p>
                                        <b className='content'>{career.current_volume_actualised ? career.current_volume_actualised : '0.00'} $ / {career.required_amount ? (career.required_amount) : '0.00'} $</b>
                                        <div className="progress">
                                            <ProgressBar className="progressBar" value={Math.round((career.current_volume_actualised/career.required_amount)*100)} showValue={false}></ProgressBar>
                                        </div>
                                        <button className="p-link" onClick={() => navigate('/career')}>More information...</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12  desktop-teams">
                            <div className="card team">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>Direct referal</h6>
                                        <p className="subtitle">{members[0] ? members[0].row_count : 0} People(s)</p>
                                    </div>
                                </div>
                                <div className="peoples">
                                    {members && members.map((member, index) => (
                                        <div key={index} className="no-picture" style={{ background: '#BAE6FF' }}>
                                        <span>{`${member.name.charAt(0).toUpperCase()} ${member.firstname.charAt(0).toUpperCase()}`}</span>
                                        </div>
                                    ))}
                                    <div className="no-picture ">
                                        <span onClick={() => navigate('/summary/direct')}>{members[0] ? members[0].row_count : 0}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Career summary end */}
                {/* News begin */}
                <div className="col-12 md:col-6 xl:col-3">
                    <div className="card timeline">
                        <div className="card-header">
                            <div className="card-title">
                                <h6>News</h6>
                                <p className="subtitle">Summary</p>
                            </div>
                        </div>
                        <ul>
                            {news_list.news && news_list.news.map((news, index) => (
                                <li key={index} className="blue">
                                    <i className="pi pi-circle-on"></i>
                                    <div className="event-content">
                                        <span className="event-title">{news.title}</span>
                                        <div dangerouslySetInnerHTML={{ __html: news.description }}></div>
                                        <span className="time">{news.ts}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <button className="p-link" onClick={() => navigate('/news')}>See all</button>
                    </div>
                </div>
                {/* News end */}
                <div className="col-12 md:col-4 xl:col-3 mobile-teams">
                    <div className="card team">
                        <div className="card-header">
                            <div className="card-title">
                                <h6>Team</h6>
                                <p className="subtitle">{members[0] && members[0].row_count}</p>
                            </div>
                        </div>
                        <div className="peoples">
                            {members && members.map((member, index) => (
                                <div key={index} className="no-picture" style={{ background: '#BAE6FF' }}>
                                <span>{`${member.name.charAt(0).toUpperCase()} ${member.firstname.charAt(0).toUpperCase()}`}</span>
                                </div>
                            ))}
                            <div className="no-picture ">
                                <span>{members[0] && members[0].row_count}</span>
                            </div>
                        </div>
                        <button className="p-link" onClick={() => navigate('/summary/direct')}>See all teams</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
