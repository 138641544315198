import AxiosService from './AxiosService';

export default class SummaryService {
    async getTransactionList() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/summary/transaction');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async getDirectList() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/summary/direct');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async getTrainingList() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/summary/training');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async getCareerList() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/summary/career');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async getTransferList() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/summary/transfer');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async getDirectUserList() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/summary/referral');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async getPackageBought() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/summary/packagebought');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
}