import React, { useState, useEffect } from 'react';
import CareerPlan from './layout/CareerPlan';
import CareerService from '../service/CareerService';
import { useNavigate } from 'react-router-dom';
import { Timeline } from 'primereact/timeline';

const Career = () => {
    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    const [current, setCurrent] = useState('');
    const [percent, setPercent] = useState([]);
    const [currentPercent, setCurrentPercent] = useState('');
    const careerService = new CareerService();

    useEffect(() => {
        careerService.getPlan().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setPlans(data.career);
            setCurrent(data.current);
        });
        careerService.getPercentPlan().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setPercent(data.percent);
            setCurrentPercent(data.current);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
            <div className="text-900 font-bold text-6xl mb-4 text-center">Income plan</div>
            <div className="text-700 text-xl mb-6 text-center line-height-3">Maximize your potential earnings and minimize risk with our training plan, designed to give you the knowledge and skills you need to succeed in the competitive world of trading.</div>
            <div className='grid'>
                <div className="col-12 lg:col-8 md:col-12 sm:col-12">
                    <h3>Your current career : {current}</h3>
                    <div className="grid">
                        {plans && plans.map((plan, index) => (
                            <div key={index} className="col-12 lg:col-6 md:col-12 sm:col-12">
                                <CareerPlan title={plan.name} gift={plan.gift_amount} required_amount={plan.required_amount}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='col-12 lg:col-4 md:col-12 sm:col-12'>
                    <h3>Your current percent : {currentPercent} %</h3>   
                    <div className='card'>                    
                    <Timeline value={percent} opposite={(item) => item.target + ' $'} content={(item) => <b className="text-color-secondary">{item.percent} %</b>} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Career;