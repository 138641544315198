import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const TrainingPlan = (props) => {
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const confirmationDialogFooter = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => confirmBuy()} className="p-button-text" autoFocus />
        </>
    );
    const confirmBuy = () => {
        props.buyPackage(props.price);
        setDisplayConfirmation(false);
    };
    return (
        <div className="p-3 h-full">
            <div className="shadow-2 p-3 h-full flex flex-column surface-card" style={{ borderRadius: '6px' }}>
                <div className="text-900 font-medium text-xl mb-2">{props.title}</div>
                <div className="text-600">{props.description}</div>
                <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">{props.price} $</span>
                </div>
                <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                        <i className="pi pi-check-circle text-green-500 mr-2"></i>
                        <span>Expiration cap: {props.percent} % {props.expInfo && ' - ' + props.expInfo}</span>
                    </li>
                </ul>
                <hr className="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                <Button label="Buy Now" className="p-3 w-full mt-auto" onClick={() => setDisplayConfirmation(true)}></Button>
                <Dialog header="Confirmation" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        <span>Please confirm your purchase.</span>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default TrainingPlan;