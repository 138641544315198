import React, { useState, useEffect,useRef } from 'react';
import TrainingService from '../service/TrainingService';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';

const Learning = () => {
    
    const navigate = useNavigate();
    const [trainings, setTrainings] = useState([]);
    const [currentPackage, setCurrentPackage] = useState(0);
    const trainingService = new TrainingService();
    const toast = useRef();
    useEffect(() => {
        trainingService.getPackage().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setTrainings(data.training);
            if(data.current)
            {
                setCurrentPackage(data.current)
            }
        }).catch((error) =>{
            navigate('/expiration');
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
            <div className="text-900 font-bold text-6xl mb-4 text-center">Training video</div>
            <Toast ref={toast} />
            <h3>Your total package : {currentPackage.toFixed(2)} $</h3>
            <h3>Currently, the videos are in French, however, we plan to include videos in English and Vietnamese in the near future.</h3>
            <div className="grid">
                {trainings && trainings.map((training, index) => (
                    <div key={index} className="col-12 lg:col-4">
                        <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                        {training.amount <= currentPackage ? (
                            training.embeded_link_video.length > 0 ? (
                                <iframe 
                                    src={"https://player.vimeo.com/video/"+training.embeded_link_video+"&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}
                                    allow="autoplay; fullscreen; picture-in-picture" 
                                    allowFullScreen 
                                    style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                                    title="PACK 1"
                                />
                            ) :
                            (
                                <>
                                    <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background:'white', border:"2px dotted black"}}>
                                        <div class="flex flex-wrap card-container yellow-container">
                                            <h3 class="flex align-items-center justify-content-center font-bold border-round m-2">COMING SOON</h3>
                                        </div>
                                    </div>
                                </>
                            )
                        ):
                            <>
                                <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background:'white', border:"2px dotted black"}}>
                                    <div class="flex flex-wrap card-container yellow-container">
                                        <h3 class="flex align-items-center justify-content-center font-bold border-round m-2">{training.amount} $ package is required to unlock this video</h3>
                                    </div>
                                </div>
                            </>
                        }
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Learning;