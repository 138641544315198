import React, { useRef,useState,useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import AxiosService from './../service/AxiosService';
import countryList from './../assets/data/countries.json';
import { Toast } from 'primereact/toast';
import ReactRecaptcha3 from 'react-google-recaptcha3';

export const Register = () => {
  const toast = useRef();
    const navigate = useNavigate();
    const url =  new AxiosService();
    const apiUrl = url.apiUrl;
    const params = useParams();
    const { member } = params;
    const dropdownValues = countryList.data;
    const [inputData, setInputData] = useState({
      sponsor: member,
      login: '',
      password: '',
      password_repeat: '',
      email: '',
      email_repeat: '',
      name: '',
      firstname: '',
      country: '',
      terms: false,
      token:''
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if(member.length === 0)
      {
        navigate('/login');
      }
      else
      {
          ReactRecaptcha3.init('6LeYpc0lAAAAANkRJgKZd3U3iLD_LAVicoODv9b4').then(
            (status) => {
              console.log(status);
            }
          );
          axios.post(apiUrl + '/auth/register', {"sponsor":member})
          .then((response) => {
          if (response.data.status.type === "error")
          {
            navigate('/login');
          }
          if (response.data.status.type === "maintenance")
          {
            navigate('/maintenance');
          }
          })
          .catch(() => {
            navigate('/login');
          });
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [inputData]);
  
    const handleChange = (e) => {
      if(e.checked)
      {
        setInputData({ ...inputData, [e.target.id]: e.checked });
      }
      setInputData({ ...inputData, [e.target.id]: e.target.value });
    };

    const getToken = async () => {
      try {
        const res = await ReactRecaptcha3.getToken();
        return res;
      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error has occured, please contact our support.', life: 3000 });
      }
    }
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
        if(inputData.password !== inputData.password_repeat)
        {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Password and password repeat not match', life: 3000 });
        }
        else if(/[&'"<>]/.test(inputData.password))
        {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Your password contains not allowed characters', life: 3000 });
        }
        else if(inputData.email !== inputData.email_repeat)
        {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Email and email repeat not match', life: 3000 });
        }
        else if(!inputData.terms)
        {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please check the validation of our terms', life: 3000 });
        }
        else if(inputData.country.length === 0)
        {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please select your country', life: 3000 });
        }
        else
        {
            const res = await getToken();
            axios.post(apiUrl + '/auth/register/new', { ...inputData, "token": res })
            .then((response) => {
              if (response.data.status.type === "error")
              {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.status.message, life: 3000 });
              }
              else if (response.data.status.type === "maintenance")
              {
                navigate('/maintenance');
              }
              else
              {
                ReactRecaptcha3.destroy();
                navigate('/welcome');
              }
            })
            .catch(() => {
              toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error has occured, please contact our support', life: 3000 });
            })
            .finally(() =>{setIsLoading(false);});
        }
      setIsLoading(false);
    };

  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <Toast ref={toast} />
          <h3>Registration : {inputData.sponsor}</h3>
          <form onSubmit={handleSubmit}>
            {/* <div className="field grid">
              <label htmlFor="sponsor" className="col-12 mb-2 md:col-3 md:mb-0">Sponsor</label>
              <div className="col-12 md:col-9 p-fluid"> */}
                <InputText id="sponsor" type="hidden" value={inputData.sponsor} disabled required/>
              {/* </div>
            </div> */}
            <div className="field grid">
              <label htmlFor="login" className="col-12 mb-2 md:col-3 md:mb-0">Login</label>
              <div className="col-12 md:col-9 p-fluid">
              <InputText id="login" keyfilter="alphanum" value={inputData.login} onChange={handleChange} placeholder="Login" required/>
              </div>
            </div>
            <div className="field grid">
              <label htmlFor="password" className="col-12 mb-2 md:col-3 md:mb-0">Password</label>
              <div className="col-12 md:col-9 p-fluid">
              <InputText id="password" type="password" value={inputData.password} onChange={handleChange} placeholder="Password" required/>
              <small>Followings characters not allowed : <b>&</b>, <b>'</b>, <b>"</b>, <b>{'<'}</b> , <b>{'>'}</b></small>
              </div>
            </div>
            <div className="field grid">
              <label htmlFor="password_repeat" className="col-12 mb-2 md:col-3 md:mb-0">Password again</label>
              <div className="col-12 md:col-9 p-fluid">
                <InputText id="password_repeat" type="password" value={inputData.password_repeat} onChange={handleChange} placeholder="Repeat your password" required/>
              </div>
            </div>
            <div className="field grid">
              <label htmlFor="email" className="col-12 mb-2 md:col-3 md:mb-0">Email</label>
              <div className="col-12 md:col-9 p-fluid">
              <InputText id="email" type="email" value={inputData.email} onChange={handleChange} placeholder="Email" required/>
              </div>
            </div>
            <div className="field grid">
              <label htmlFor="email_repeat" className="col-12 mb-2 md:col-3 md:mb-0">Email again</label>
              <div className="col-12 md:col-9 p-fluid">
              <InputText id="email_repeat" type="email" value={inputData.email_repeat} onChange={handleChange} placeholder="Repeat your email" required/>
              </div>
            </div>
            <div className="field grid">
              <label htmlFor="name" className="col-12 mb-2 md:col-3 md:mb-0">Lastname</label>
              <div className="col-12 md:col-9 p-fluid">
              <InputText id="name" value={inputData.name} onChange={handleChange} placeholder="Name" required/>
              </div>
            </div>            
            <div className="field grid">
              <label htmlFor="firstname" className="col-12 mb-2 md:col-3 md:mb-0">Firstname</label>
              <div className="col-12 md:col-9 p-fluid">
              <InputText id="firstname" value={inputData.firstname} onChange={handleChange} placeholder="Firstname" required/>
              </div>
            </div>
            <div className="field grid">
              <label htmlFor="country" className="col-12 mb-2 md:col-3 md:mb-0">Country</label>
              <div className="col-12 md:col-9 p-fluid">
                <Dropdown id="country" value={inputData.country} onChange={handleChange} options={dropdownValues} optionValue="code" optionLabel="name" placeholder="Country" required></Dropdown>        
              </div>
            </div>
            <div className="field grid">
              <div className="col-1 md:col-1">
                <Checkbox id="terms" checked={inputData.terms} onChange={e => setInputData({ ...inputData, "terms": e.checked })} required/>
              </div>
              <label htmlFor="terms" className="col-11 mb-2 md:col-11 md:mb-0"><span className='mr-1'>Please confirm that you have read and accept our</span> <a href="https://files.smarttraininginstitute.com/upload/pdf/terms_sti.pdf" style={{ cursor: 'pointer'}} target="_BLANK" rel="noreferrer">terms</a></label>
            </div>
            <Button label="Sign up" type="submit" disabled={isLoading} className='mb-5'>
            {isLoading && <i className="pi pi-spin pi-spinner" />}
            </Button>
          </form>
        </div>
        <div className="login-footer">
          <h4>Smart training institute</h4>
          <h6>Copyright Ⓒ 2023</h6>
        </div>
      </div>
    </div>
  );
};
