import React from 'react';
import { Button } from 'primereact/button';

const Marketing = () => {
    
    return (
        <div className="grid">
            <div className="col">
                <h3>Marketing</h3>
                <h3><a href="https://files.smarttraininginstitute.com/upload/pdf/capital.pdf" className="p-link bg-primary text-white p-2" style={{ cursor: 'pointer'}} target="_BLANK" rel="noreferrer"><i className="pi pi-download"></i> DOWNLOAD CAPITAL SHARES</a></h3>
                <h3><a href="https://files.smarttraininginstitute.com/upload/pdf/certificat.pdf" className="p-link bg-primary text-white p-2" style={{ cursor: 'pointer'}} target="_BLANK" rel="noreferrer"><i className="pi pi-download"></i> DOWNLOAD CERTIFICATE OF INCORPORATION</a></h3>
                <h3><a href="https://files.smarttraininginstitute.com/upload/pdf/pre_fr_compress.pdf" className="p-link bg-primary text-white p-2" style={{ cursor: 'pointer'}} target="_BLANK" rel="noreferrer"><i className="pi pi-download"></i> DOWNLOAD PRESENTATION IN FRENCH</a></h3>
                <h3><a href="https://files.smarttraininginstitute.com/upload/pdf/pre_en_compress.pdf" className="p-link bg-primary text-white p-2" style={{ cursor: 'pointer'}} target="_BLANK" rel="noreferrer"><i className="pi pi-download"></i> DOWNLOAD PRESENTATION IN ENGLISH</a></h3>
                <h3><a href="https://files.smarttraininginstitute.com/upload/pdf/terms_sti.pdf" className="p-link bg-primary text-white p-2" style={{ cursor: 'pointer'}} target="_BLANK" rel="noreferrer"><i className="pi pi-download"></i> TERMS OF STI</a></h3>
            </div>
        </div>
    );
};

export default Marketing;