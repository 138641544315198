import React, { useState, useEffect } from 'react';
import FaqService from '../service/FaqService';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';

const Faq = () => {
    
    const navigate = useNavigate();
    const [faqs, setfaqs] = useState([]);
    const faqService = new FaqService();

    useEffect(() => {
        faqService.getFaqList().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setfaqs(data);
            console.log(faqs);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="grid">
            <div className="col">
                <h3>FAQ</h3>
                <Accordion activeIndex={0}>  
                {faqs && faqs.map((faqsList, index) => (
                    <AccordionTab key={index} header={faqsList.title}>
                        <div dangerouslySetInnerHTML={{ __html: faqsList.description }}></div>
                    </AccordionTab>
                ))}
                </Accordion>        
                    
            </div>
        </div>
    );
};

export default Faq;