import React, { useState, useEffect } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import SummaryService from '../service/SummaryService';
import { useNavigate } from 'react-router-dom';

const Tincomes = () => {
    const navigate = useNavigate();
    const [trainingListData, setTrainingListData] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);

    const trainingService = new SummaryService();

    useEffect(() => {
        setLoading(true);

        trainingService.getTrainingList().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setTrainingListData(getlistFormat(data));
            setLoading(false);
        });

        initFilters();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getlistFormat = (data) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);
            return d;
        });
    };

    const initFilters = () => {
        setFilters({
            amount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            rate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    return (
        <div className="grid table-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Training summary incomes</h5>
                    <DataTable
                        value={trainingListData}
                        paginator
                        className="p-datatable-gridlines"
                        showGridlines
                        rows={10}
                        dataKey="id"
                        filters={filters}
                        filterDisplay="menu"
                        loading={loading}
                        responsiveLayout="scroll"
                        emptyMessage="No Transactions found."
                    >
                        
                        <Column field="amount" header="Amount" filter filterPlaceholder="Search by Amount" style={{ minWidth: '12rem' }} sortable/>
                        <Column field="rate" header="Rate" filter filterPlaceholder="Search by Rate" style={{ minWidth: '12rem' }} sortable/>
                        <Column header="Date" filterField="date" dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate} sortable/>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default Tincomes;