import AxiosService from './AxiosService';

export default class DashboardService {
    async getAmount() {
      const call =  new AxiosService().getAxiosInstanceJwt();
      try {
        const res = await call.get('/dashboard/main/amount');
        return res.data.data;
      } catch (error) {
        return error.response.status;
      }
    }
    async getChart() {
      const call =  new AxiosService().getAxiosInstanceJwt();
      try {
        const res = await call.get('/dashboard/main/chart');
        return res.data.data;
      } catch (error) {
        return error.response.status;
      }
    }
    async getNews() {
      const call =  new AxiosService().getAxiosInstanceJwt();
      try {
        const res = await call.get('/dashboard/main/news');
        return res.data.data;
      } catch (error) {
        return error.response.status;
      }
    }
    async getCareer() {
      const call =  new AxiosService().getAxiosInstanceJwt();
      try {
        const res = await call.get('/dashboard/main/career');
        return res.data.data;
      } catch (error) {
        return error.response.status;
      }
    }
    async getDirect() {
      const call =  new AxiosService().getAxiosInstanceJwt();
      try {
        const res = await call.get('/dashboard/main/direct');
        return res.data.data.direct_member_list;
      } catch (error) {
        return error.response.status;
      }
    }
    async checkCareerReach() {
      const call =  new AxiosService().getAxiosInstanceJwt();
      try {
        const res = await call.post('/dashboard/main/checkcareerreach');
        return res.data;
      } catch (error) {
        return error.response.status;
      }
    }
    async getDepositPending() {
      const call =  new AxiosService().getAxiosInstanceJwt();
      try {
          const res = await call.get('/dashboard/deposit/depositpending');
          return res.data.data;   
      } catch (error) {
          return error.response.status;
      }
  }
}