import AxiosService from './AxiosService';

export default class TrainingService {
    async getPackage() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/training/package');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async buyPackage(amount) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.post('/dashboard/training/buypackage', { "package": amount });
            return res.data;
        } catch (error) {
            return error.response.status;
        }
    }
}