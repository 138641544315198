import React, { useRef, useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import AxiosService from './../service/AxiosService';

export const Login = () => {
    const toast = useRef();
    const navigate = useNavigate();
    const url =  new AxiosService();
    const apiUrl = url.apiUrl;
    const [inputData, setInputData] = useState({
      login: '',
      password: '',
      ga: ''
    });
    const [inputDataForgot, setInputDataForgot] = useState({
        email: ''
      });
    
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        localStorage.removeItem('token');
    }, [])
      
    const handleChange = (e) => {
        setInputData({ ...inputData, [e.target.id]: e.target.value });
    };

    const handleChangeForgot = (e) => {
        setInputDataForgot({ ...inputDataForgot, [e.target.id]: e.target.value });
    };
  
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if(inputData.login.length === 0 && inputData.password.length === 0)
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please fill the form', life: 3000 });
            setIsLoading(false);
        }
        else
        {
            axios.post(apiUrl + '/auth/login', inputData)
            .then((response) => {
            if (response.data.status.type === "success")
            {
                const token = response.data.data.token;
                localStorage.setItem('token', token);
                navigate('/dashboard', { replace: true });
                setIsLoading(false);
            }
            else if(response.data.status.type === "maintenance")
            {
                navigate('/maintenance', { replace: true });
            }
            else
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.status.message, life: 3000 });
                setIsLoading(false);
            }
            })
            .catch(() => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error has occured, please contact our support', life: 3000 });
                setIsLoading(false);
            });
        }
    };

    const handleSubmitForgot = () => {
        setIsLoading(true);
        if(inputDataForgot.email.length === 0)
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please fill the form', life: 3000 });
            setIsLoading(false);
        }
        else
        {
            axios.post(apiUrl + '/auth/recovery', inputDataForgot)
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.status.message, life: 3000 });
                setIsLoading(false);
            })
            .catch(() => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error has occured, please contact our support', life: 3000 });
                setIsLoading(false);
            });
        }
    };
    const [displaySignUp, setDisplaySignUp] = useState(false);
    const [displayForgot, setDisplayForgot] = useState(false);
    const dialogSignUpFooter = <Button type="button" label="Dismiss" onClick={() => setDisplaySignUp(false)} icon="pi pi-check" className="p-button-secondary" />;
    const dialogForgotFooter = <Button type="button" label="Dismiss" onClick={() => setDisplayForgot(false)} icon="pi pi-check" className="p-button-secondary" />;

    return (
        <div className="login-body">
            <div className="login-wrapper">
                <div className="login-panel">
                    <Toast ref={toast} />
                    <img src="assets/layout/images/logook.svg" alt="Smart-training-institute" />
                    <form onSubmit={handleSubmit} className='p-fluid'>
                        <div className="field grid">
                            <label htmlFor="login" className="col-12 mb-2 md:col-3 md:mb-0">Login</label>
                            <div className="col-12 md:col-9 p-fluid">
                            <InputText id="login" keyfilter="alphanum" value={inputData.login} onChange={handleChange} placeholder="Login" autoComplete="username" required/>
                            </div>
                        </div>
                        <div className="field grid">
                            <label htmlFor="login" className="col-12 mb-2 md:col-3 md:mb-0">Password</label>
                            <div className="col-12 md:col-9 p-fluid">
                                <InputText id="password" type="password" value={inputData.password} onChange={handleChange} placeholder="Password" autoComplete="current-password" required/>
                            </div>
                        </div>
                        <div className="field grid">
                            <label htmlFor="login" className="col-12 mb-2 md:col-3 md:mb-0">Google auth</label>
                            <div className="col-12 md:col-9 p-fluid">
                                <hr/>
                                <InputText id="ga" type="number" keyfilter="int" value={inputData.ga} onChange={handleChange} placeholder="Google auth code"/>   
                                <p style={{ color: 'red'}}>Optional: If you have enabled Google Authenticator</p>                 
                            </div>
                        </div>
                        <Button label="Sign in" type="submit" disabled={isLoading} className='mt-2'>
                            {isLoading && <i className="pi pi-spin pi-spinner" />}
                        </Button>
                    </form>
                    <button className="p-link text-center mt-2" onClick={() => setDisplayForgot(true)}>forget password?</button>
                    <p>Don’t you have an account, <button className="p-link" onClick={() => setDisplaySignUp(true)}>sign up</button></p>
                    <Dialog header="Sign up" visible={displaySignUp} modal footer={dialogSignUpFooter} onHide={() => setDisplaySignUp(false)}>
                        <p>You need a sponsor link to sign up. Thank you.</p>
                    </Dialog>
                    <Dialog header="Forgot password" visible={displayForgot} modal footer={dialogForgotFooter} onHide={() => setDisplayForgot(false)}>
                        <p>Enter your email to receive a recovery password</p>
                        <InputText id="email" placeholder="Email" value={inputDataForgot.email} onChange={handleChangeForgot}/>
                        <Button label="Send" type="submit" disabled={isLoading} onClick={handleSubmitForgot}>
                            {isLoading && <i className="pi pi-spin pi-spinner" />}
                        </Button>
                    </Dialog>
                </div>
                <div className="login-footer">
                    <h4>Smart training institute</h4>
                    <h6>Copyright Ⓒ 2023</h6>
                </div>
            </div>
        </div>
    );
};
