import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import App from './App';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Recovery } from './pages/Recovery';
import { Welcome } from './pages/Welcome';
import { Expiration } from './pages/Expiration';
import { Confirmation } from './pages/Confirmation';
import { Maintenance } from './pages/Maintenance';
import { Logout } from './pages/Logout';
import AxiosService from './service/AxiosService';
import axios from 'axios';

export const ctx = React.createContext();

const AppWrapper = (props) => {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const [colorScheme, setColorScheme] = useState('light');
    
    const ProtectedRoute = ({ children }) => {
        let location = useLocation();
        const url =  new AxiosService();
        const apiUrl = url.apiUrl;
        const token = localStorage.getItem('token');
        if (token) 
        {
            return children;
        }
        else
        {
            return <Navigate to="/login" state={{ from: location }} />;
        } 
      };
    return (
        <Routes>
            <Route path="/login" element={<Login colorScheme={colorScheme} />} />
            <Route path="/register/:member" element={<Register colorScheme={colorScheme} />} />
            <Route path="/recovery/:token" element={<Recovery colorScheme={colorScheme} />} />
            <Route path="/error" element={<Error colorScheme={colorScheme} />} />
            <Route path="/notfound" element={<NotFound colorScheme={colorScheme} />} />
            <Route path="/welcome" element={<Welcome colorScheme={colorScheme} />} />
            <Route path="/Maintenance" element={<Maintenance colorScheme={colorScheme} />} />
            <Route path="/expiration" element={<Expiration colorScheme={colorScheme} />} />
            <Route path="/logout" element={<Logout colorScheme={colorScheme} />} />
            <Route path="/confirmation/:token" element={<Confirmation colorScheme={colorScheme} />} />
            <Route path="*" element={<ProtectedRoute><App setColorScheme={setColorScheme}/></ProtectedRoute>}></Route>
        </Routes>
    );
};

export default AppWrapper;
